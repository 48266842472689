import React from 'react';
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  font-size: 2rem;
  color: ${props => (props.color ? props.color : 'black')};
`;

export default ({ color }) => (
  <LoadingContainer>
    <LoadingIcon className="icon-spin" color={color} />
  </LoadingContainer>
);
