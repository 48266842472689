import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';
import AsyncSelect from 'react-select/lib/Async';
import { components } from 'react-select';
import moment from 'moment';
import Select from 'react-select';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import Modal from '../containers/common/Modal';
import config from '../config';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BoldText,
  Text,
  RegularText,
} from '../components/common/Text';
import AddMedicinesModal from '../components/prescription/AddMedicinesModal';
import EditMedicineModal from '../components/prescription/EditMedicineModal';
import AddLabTestModal from '../components/prescription/AddLabTestModal';
import PrescriptionSentSuccessModal from '../components/prescription/PrescriptionSentSuccessModal';
import MedicineCard from '../components/prescription/MedicineCard';
import {
  getMedicineCartItemsByDigitisationId,
  addMedicine,
  deleteMedicine,
  addLabTest,
  deleteLabTest,
  previewPrescription,
  sendPrescription,
  addAdvicePrescription,
  removeAdvicePrescription,
  getPrescriptionFiles,
  markInvalidPrescription,
  getDigitisedLabs,
  getDigitisedMeds,
  getDigitisedAdvices,
  getRelativesForDigitisation,
  getPastAppointments,
  getDoctors,
  getCenters,
  getDiseases,
  getDigitiseRequest,
  updateCriticalIllness
} from '../services';
import {
  setPrescriptionId,
  setTreatmentPlan,
  resetPrescription,
  fetchConsults,
} from '../actions';
import AddDoctorModal from '../components/prescription/AddDoctorModal';
import AddCenterModal from '../components/prescription/AddCenterModal';
import AddDiagnosisModal from '../components/prescription/AddDiagnosisModal';
import PharmacyBasedMedicineCartListModel from '../components/prescription/PharmacyBasedMedicineCartListModel';
import DigitizeVisionPrescription from './DigitizeVisionPrescription/DigitizeVisionPrescription';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

const StyledNormalInputBox = styled.input`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin-top: 0.625rem;
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: #4f34d2;
`;

const TappableAdviceText = styled(TappableText)`
  font-size: 2.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 1rem;
`;


const StyledRemoveButton = styled(StyledTappable)`
  margin: 0 0.7rem;
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* box-shadow: 0 -0.3rem 0.625rem 0 rgba(0, 0, 0, 0.09); */
  padding: 0.625rem 1rem;
  margin-bottom: 0.5rem;
  background: white;
  flex-shrink: 0;
`;

const AddedTestsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow-x: scroll;
  /* -webkit-overflow-scrolling: touch; */
  padding-bottom: 1.75rem;
`;

const AddedTestContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  padding: 0.5rem 1rem;
`;
const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;


const AdviceComponent = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  /* background: #f4f4f4; */
  /* box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 2rem; */
  padding: 0.5rem 1rem;
`;

const RemoveIcon = styled(FaTimes)`
  margin-left: 1.125rem;
  height: 1.125rem;
  width: 1.125rem;
  align-self: center;
`;

const LabText = styled(RegularText)`
  font-size: 1.6rem;
`;

const AdviceText = styled(RegularText)`
  font-size: 1.6rem;
  align-self: flex-start;
`;

const AddAdviceContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
`;



const secondaryDiagnosisDisabledMsg = [
  {
    label: 'You can not select more than 5 options',
    name: 'You can not select more than 5 options',
    isDisabled: true
  }
]

class DigitizePrescriptionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      probableDiagnosis: props.probableDiagnosis,
      icdCode: props.icdCode,
      doctorName: '',
      secondaryDiagnosis: props.secondaryDiagnosis,
      secondaryDiagnosisIcdCode: props.secondaryDiagnosisIcdCode,
      doctorValue: null,
      diagnosisValue: `${props.digitisationDetails.diagnosis}`,
      secondaryDiagnosisValue: `${props.digitisationDetails.secondaryDiagnosis}`,
      digitisationStatus : this.props.digitisationDetails.status,
      editMedicine:{},
      centerValue: null,
      appointmentDate: '',
      prescriptionValidityDate: '',
      centerName: '',
      centerAddress: '',
      relativeValue: null,
      advice: '',
      medicinesModalVisible: false,
      labTestModalVisible: false,
      selectedMedicineToEdit: {},
      showSendPrescriptionSuccess: false,
      successModalMessage: 'Prescription Sent Successfully!',
      invalidOption: null,
      addDoctorModal: false,
      addDiagnosisModal: false,
      addCenterModal: false,
      isCritical: false,
      secondaryDiagnosisModal: false,
      editSubstituteMedicineModalVisible: false,
      isBlacklistedCentre :false,
      disableUserToChangePatient :false,
    };
    this.doctorSelectRef = React.createRef();
    this.diseaseSelectRef = React.createRef();
    this.secondaryDiseaseSelectRef = React.createRef();
    this.centerSelectRef = React.createRef();
    console.log({ props, state: this.state });
    this.reloadCartItems()
  }

  hideModal = () => {
    this.setState({
      medicinesModalVisible: false,
      labTestModalVisible: false,
      addDoctorModal: false,
      addDiagnosisModal: false,
      addCenterModal: false,
      editSubstituteMedicineModalVisible: false
    });
  };

  openEditSubstituteMedicineModal = (medicine) => {
    
    this.setState({
      editMedicine:medicine,
      editSubstituteMedicineModalVisible: true,
    });
  }

  hidePrescriptionSentSuccessModal = () => {
    this.setState({
      showSendPrescriptionSuccess: false,
    });
  };

  openAddMedicineDialog = () => {
    console.log('openAddMedicineDialog called');
    this.setState({
      medicinesModalVisible: true,
    });
  };

  openAddLabTestDialog = () => {
    console.log('openAddLabTestDialog called');
    this.setState({
      labTestModalVisible: true,
    });
  };

  saveCartItems = (body, closeModal) => {

    this.getMedicinesCartItems();
    if (closeModal) {

      this.setState({ medicinesModalVisible: false });
    }
  };

  addMedicine = (medicine, closeModal) => {
    const originalMedName = medicine.prescribedMedNameInRx ? medicine.prescribedMedNameInRx : '';
    const body = {
      digitisationId: this.props.activeConsult.digitisationRequestId,
      name: medicine.medicineName,
      genericName: medicine.genericName,
      dosage: medicine.dosage,
      prescribedQuantity:
        medicine.dosage === 'Other' ? medicine.prescribedQuantity : null,
      duration: medicine.durationValue,
      durationType: medicine.duration,
      meal: medicine.mealPref,
      notes:
        medicine.dosage === 'Other'
          ? medicine.otherDosage
          : medicine.notes,
      dimDrugId: medicine.dimDrugId,
      coveredUnderOpd: medicine.coveredUnderOpd,
      prescribedMedInRx: originalMedName,
      isSubstitute: medicine.isSubstitute,
      prescribedDrugId: medicine.prescribedDrugId,
    };
    console.log({ medicine, body });

    addMedicine(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addMedicine');
        this.getMedicines();

        // check if close modal then close
        if (closeModal) {
          this.setState({ medicinesModalVisible: false });
        }
      })
      .catch((err) => {
        console.log({ err }, 'error in add medicine');
      });
  };
  reloadCartItems = () => {
    this.getMedicinesCartItems();
  }

  addLabTest = (lab) => {
    console.log({ lab });
    const { dispatch } = this.props;

    const body = {
      digitisationId: this.props.activeConsult.digitisationRequestId,
      labId: lab.id,
      labName: lab.value,
    };

    addLabTest(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addLabTest');
        this.getLabs();
      })
      .catch((err) => {
        console.log({ err }, 'error in add lab test');
      });
  };

  removeLabTest = (lab) => {
    deleteLabTest(
      this.props.authToken,
      lab.labId,
      lab.prescriptionId,
      this.props.digitisationRequestId
    )
      .then((result) => {
        this.getLabs();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  addAdvice = () => {
    if (this.state.advice.length === 0) {
      return;
    }

    const body = {
      digitisationId: this.props.activeConsult.digitisationRequestId,
      advice: this.state.advice,
    };

    addAdvicePrescription(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addAdvicePrescription');
        this.getAdvices();
        this.setState({ advice: '' });
      })
      .catch((err) => {
        console.log({ err }, 'error in add advice');
      });
  };

  removeAdvice = (advice) => {
    removeAdvicePrescription(
      this.props.authToken,
      advice.id,
      advice.prescriptionId,
      this.props.digitisationRequestId
    )
      .then((result) => {
        this.getAdvices();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  getLabs = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    getDigitisedLabs(authToken, digitisationRequestId).then((res) => {
      dispatch({
        type: 'SET_LAB_TESTS',
        payload: {
          labs: res.labs,
        },
      });
    });
  };

  getMedicines = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    getDigitisedMeds(authToken, digitisationRequestId).then((res) => {
      dispatch({
        type: 'SET_MEDICINES',
        payload: {
          medicines: res.medicines,
        },
      });
    });
  };

  getMedicinesCartItems = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;

    getMedicineCartItemsByDigitisationId(authToken, digitisationRequestId).then((res) => {
      dispatch({
        type: 'SET_CART_ITEMS',
        payload: {
          cartItems: res,
        },
      });
    });
  };

  getAdvices = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    getDigitisedAdvices(authToken, digitisationRequestId).then((res) => {
      dispatch({
        type: 'SET_ADVICES',
        payload: {
          advices: res.advices,
        },
      });
    });
  };

  getRelatives = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    if (!digitisationRequestId) {
      return;
    }
    getRelativesForDigitisation(authToken, digitisationRequestId).then(
      (res) => {
        dispatch({
          type: 'SET_RELATIVES',
          payload: {
            relatives: res.relatives,
          },
        });
      }
    );
  };

  fetchPastAppointments = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    if (!digitisationRequestId) {
      return;
    }
    getPastAppointments(authToken, digitisationRequestId).then((res) => {
      dispatch({
        type: 'SET_PAST_APPOINTMENTS',
        payload: {
          consultations: res.consultations,
        },
      });
    });
  };

  getConsultationInfo = () => {
    const { dispatch, digitisationRequestId, authToken } = this.props;
    if (digitisationRequestId) {
      getDigitiseRequest(authToken, digitisationRequestId)
      .then((res) => {
        dispatch({
          type: 'SET_DIGITISATION',
          payload: res.digitisationDetails,
        });
        this.props.dispatch({
          type: 'SET_CONSULTATION',
          payload: res.sourceDetails.consultationInfo
        });
      })
      .catch((err) => {
        console.log({ err }, 'error in calling getCOnsultationInfo');
      });
    }
  };

  componentDidMount() {
    this.getLabs();
    this.getMedicines();
    this.getMedicinesCartItems();
    this.getAdvices();
    this.getRelatives();
    this.fetchPastAppointments();
    this.getConsultationInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    const appointmentDate = moment(this.props.digitisationDetails.appointmentDate);
    const formattedAppointmentDate = appointmentDate.isValid() ? appointmentDate.format('YYYY-MM-DD') : null;
    const prescriptionValidityDate = this.props.digitisationDetails.prescriptionValidityDate && moment(this.props.digitisationDetails.prescriptionValidityDate);
    const formattedprescriptionValidityDate = prescriptionValidityDate && prescriptionValidityDate.isValid() ? prescriptionValidityDate.format('YYYY-MM-DD') : null;
    if (prevProps.digitisationDetails !== this.props.digitisationDetails) {
      this.setState(
        {
          probableDiagnosis: '',
          icdCode: '',
          secondaryDiagnosisIcdCode: '',
          secondaryDiagnosis: this.props.digitisationDetails.secondaryDiagnosis || '',
          secondaryDiagnosisValue: this.props.digitisationDetails.secondaryDiagnosis?.split(',').map((d) =>
            ({ label: d, value: d, code: null })) || [],
          doctorName: this.props.digitisationDetails.doctorName || '',
          appointmentDate: formattedAppointmentDate || '',
          prescriptionValidityDate: formattedprescriptionValidityDate || '',
          advice: '',
          centerName: this.props.digitisationDetails.centerName || '',
          centerAddress: '',
          diagnosisValue: this.props.digitisationDetails.diagnosis || [],
          relativeValue: null,
          centerValue: {
            label: this.props.digitisationDetails.centerName,
            value: this.props.digitisationDetails.centerName,
          },
          isBlacklistedCentre:false,
          digitisationStatus:this.props.digitisationDetails.status
        },
        () => {
          this.props.dispatch({
            type: 'SET_PROBABLE_DIAGNOSIS',
            payload: this.props.digitisationDetails.diagnosis || '',
          });
          this.props.dispatch({
            type: 'SET_SECONDARY_DIAGNOSIS',
            payload: this.props.digitisationDetails.secondaryDiagnosis || '',
          });
          this.props.dispatch({
            type: 'SET_CENTER_NAME',
            payload: this.props.digitisationDetails.centerName || '',
          });
          this.props.dispatch({
            type: 'SET_CENTER_ADDRESS',
            payload: this.props.digitisationDetails.centerAddress || '',
          });
          this.props.dispatch({
            type: 'SET_RELATIVE_ID',
            payload: this.props.digitisationDetails.relativeId || -1,
          });
          this.props.dispatch({
            type: 'SET_DIGITISATION_CONSULTATION',
            payload: null,
          });
          this.props.dispatch({
            type: 'SET_APPOINTMENT_DATE',
            payload: formattedAppointmentDate || '',
          });
          this.props.dispatch({
            type: 'SET_PRESCRIPTION_VALIDITY_DATE',
            payload: formattedprescriptionValidityDate || '',
          });
          this.props.dispatch({
            type: 'SET_CENTER_ID',
            payload: this.props.digitisationDetails.centerId,
          });
          this.props.dispatch({
            type: 'SET_IS_CENTRE_BLACKLISTED',
            payload: false,
          });
          this.props.dispatch({
            type:'SET_CENTRE_BLACKLISTED_NAME',
            payload:' ',
          })
          this.setState({
            centerValue: {
              label: this.props.digitisationDetails.centerName,
              value: this.props.digitisationDetails.centerId,
            },
          });
          this.props.dispatch({
            type: 'SET_DOCTOR_NAME',
            payload: this.props.digitisationDetails.doctorName,
          });
          this.props.dispatch({
            type: 'SET_DOCTOR_ID',
            payload: this.props.digitisationDetails.doctorId,
          });
          this.setState({
            doctorValue: {
              label: this.props.digitisationDetails.doctorName,
              value: this.props.digitisationDetails.doctorId,
            },
          });
          if (this.props.digitisationDetails.status !== 'pending') {
            const selectedUser = this.props.relatives.find(obj => Number(obj.userSelected))
            this.setState({
              relativeValue: {
                label: selectedUser && selectedUser.name || this.props.digitisationDetails.patientName,
                value:  selectedUser && selectedUser.relativeId || this.props.digitisationDetails.relativeId || -1,
              },
              disableUserToChangePatient : (this.props.digitisationDetails.sponsorId == config.starSponsorId && selectedUser && selectedUser.relativeId)
            });
          }

          this.props.dispatch({
            type: 'SET_PROBABLE_DIAGNOSIS',
            payload: this.props.digitisationDetails.diagnosis || '',
          });
          this.props.dispatch({
            type: 'SET_ICD_CODE',
            payload: null,
          });
          this.props.dispatch({
            type: 'SET_SECONDARY_ICD_CODE',
            payload: null,
          });
          this.props.dispatch({
            type: 'SET_SECONDARY_DIAGNOSIS',
            payload: this.props.digitisationDetails.secondaryDiagnosis || '',
          });
          // this.props.dispatch({
          //   type: 'SET_DIGITISATION',
          //   payload: this.props.digitisationDetails,
          // });
          this.setState({
            diagnosisValue: this.props.digitisationDetails.diagnosis?.split(',').map((d) =>
              ({ label: d, value: d, code: null })) || []
          });

          this.setState({
            isCritical: this.props.digitisationDetails.isCritical
          }, () => { console.log('critical', this.state.isCritical); })
        }

      );
    }
    if (
      prevProps.activeConsult.digitisationRequestId !==
      this.props.activeConsult.digitisationRequestId
    ) {
      this.getLabs();
      this.getMedicines();
      this.getAdvices();
      this.getMedicinesCartItems();
      this.getRelatives();
      this.fetchPastAppointments();
      this.getConsultationInfo();
      this.setState(
        {
          probableDiagnosis: '',
          icdCode: '',
          doctorName: '',
          appointmentDate: '',
          prescriptionValidityDate: '',
          advice: '',
          centerName: this.props.digitisationDetails.centerName || '',
          centerAddress: '',
          isBlacklistedCentre:false,
        },
        () => {
          this.props.dispatch({
            type: 'SET_CENTER_NAME',
            payload: this.props.digitisationDetails.centerName || '',
          });
          this.props.dispatch({
            type: 'SET_CENTER_ADDRESS',
            payload: '',
          });
          this.props.dispatch({
            type: 'SET_RELATIVE_ID',
            payload: null,
          });
          this.props.dispatch({
            type: 'SET_DIGITISATION_CONSULTATION',
            payload: null,
          });
          this.props.dispatch({
            type: 'SET_IS_CENTRE_BLACKLISTED',
            payload: false,
          });
          this.props.dispatch({
            type:'SET_CENTRE_BLACKLISTED_NAME',
            payload:'',
          })
        }
      );
    }
  }

  deleteMedicine = (medicine, index) => {
    deleteMedicine(
      this.props.authToken,
      medicine.id,
      medicine.prescriptionId,
      this.props.digitisationRequestId
    )
      .then((result) => {
        this.getMedicines();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  renderMedicine = (medicine, index) => {
    return (
      <MedicineCard
        medicine={medicine}
        deleteMedicine={() => this.deleteMedicine(medicine, index)}
        key={`${index}_${medicine.name}`}
      />
    );
  };
  renderMedicineCartItem = (medicine) => {
    return (
      <PharmacyBasedMedicineCartListModel
        reloadCartItems={() => this.reloadCartItems()}
        result={medicine}
        openEditSubstituteMedicineModal={(medicine) => this.openEditSubstituteMedicineModal(medicine)}
        isButtonsDisabled={this.state.digitisationStatus==="complete"? true :false }
      ></PharmacyBasedMedicineCartListModel>
    );

  };

  renderAdvices = (ele, index) => {
    return (
      <AdviceComponent key={ele.adviceId} onTap={() => this.removeAdvice(ele)}>
        <AdviceText>
          {index + 1}. {ele.advice}
        </AdviceText>
        <RemoveIcon />
      </AdviceComponent>
    );
  };

  renderLabTest = (ele, idx) => (
    <AddedTestContainer key={ele.name}>
      <LabText>{ele.name}</LabText>
      {this.props.digitisationDetails.status !== 'complete'?<StyledRemoveButton onTap={() => this.removeLabTest(ele)}>
        <RemoveIcon />
      </StyledRemoveButton>:('')}
      
    </AddedTestContainer>
  );

  previewPrescription = () => {
    previewPrescription(
      this.props.authToken,
      this.props.prescriptionId,
      this.state.probableDiagnosis,
      this.state.icdCode,
      this.props.activeConsult.consultationId,
      this.props.activeConsult.doctorType
    )
      .then((result) => {
        this.props.dispatch(setTreatmentPlan(result));
        this.props.dispatch(setPrescriptionId(result.prescriptionId));
        window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  sendPrescription = () => {
    console.log('send prescription called');
    const transcriberDoctorId = this.props.transcriberAccountActive
      ? this.props.user.doctorId
      : null;
    let filesArray = null;
    if (this.props.files) {
      filesArray = [];
      this.props.files.forEach((file) => {
        filesArray.push(file.url);
      });
    }
    this.setState({ loading: true });
    sendPrescription(
      this.props.authToken,
      this.props.prescriptionId,
      this.state.probableDiagnosis,
      this.props.activeConsult.consultationId,
      this.props.activeConsult.doctorType,
      this.props.transcriberAccountActive,
      transcriberDoctorId,
      filesArray
    )
      .then((result) => {
        console.log('prescription sent success');

        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Prescription Sent Successfully',
          probableDiagnosis: '',
          advice: '',
          loading: false,
        });
        this.props.dispatch(resetPrescription());
        this.fetchPastAppointments();
        if (this.props.transcriberAccountActive) {
          this.props.dispatch(
            fetchConsults({
              authToken: this.props.authToken,
              filters: this.props.filters,
              selectedSponsorId: this.props.selectedSponsorId,
            })
          );
        }
        // window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  markPrescriptionInvalid = () => {
    markInvalidPrescription(
      this.props.authToken,
      this.props.activeConsult.consultationId,
      this.state.invalidOption
    )
      .then((result) => {
        console.log({ result });
        this.setState({
          showSendPrescriptionSuccess: true,
          successModalMessage: 'Prescription marked Invalid!',
        });
        this.props.dispatch(
          fetchConsults({
            authToken: this.props.authToken,
            filters: this.props.filters,
            selectedSponsorId: this.props.selectedSponsorId,
          })
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  onAppointmentDateChange = (text) => {
    console.log({ text }, 'onAppointmentDateChange');
    const { appointmentDate } = this.state;

    if (text.length < appointmentDate.length) {
      // means deleting
      if (text.length === 5 || text.length === 8) {
        text = text.slice(0, -1);
      }
    } else {
      if (text.length === 4 || text.length === 7) {
        text += '-';
      }
    }
    this.setState({
      appointmentDate: text,
    });
    this.props.dispatch({
      type: 'SET_APPOINTMENT_DATE',
      payload: text,
    });
  };

  onPrescriptionValidityDateChange = (text) => { 
    const formattedDate = moment(text).format('YYYY-MM-DD') || null;
    this.setState({
      prescriptionValidityDate: formattedDate,
    });
  
    this.props.dispatch({
      type: 'SET_PRESCRIPTION_VALIDITY_DATE',
      payload: formattedDate,
    });
  }

  setRelativeId = (text) => {
    this.props.dispatch({
      type: 'SET_RELATIVE_ID',
      payload: text.value,
    });
    this.setState({
      relativeValue: text,
    })
  };

  setDigitisationConsultation = (option) => {
    this.props.dispatch({
      type: 'SET_DIGITISATION_CONSULTATION',
      payload: option,
    });
  };

  formDisable = () => {
    return (
      this.props.digitisationConsultation &&
      this.props.digitisationConsultation.prescriptionUrl
    );
  };

  opdCriticalChange = async (ev) => {
    // console.log("target---",ev.currentTarget.checked);
    this.setState({ isCritical: ev.currentTarget.checked })
    this.props.dispatch({
      type: 'SET_IS_CRITICAL',
      payload: ev.currentTarget.checked,
    });
    if (this.props.digitisationDetails.status === 'complete'){
      const response = await updateCriticalIllness(this.props.authToken, this.props.digitisationDetails.digitisationRequestId,  ev.currentTarget.checked)
      if (!response || !response.message || response.message === 'failure'){
        this.setState({ isCritical: false })
        this.props.dispatch({
          type: 'SET_IS_CRITICAL',
          payload: false,
        });
      }
    }
  };

  getDisease = (input) => {
    return getDiseases(this.props.authToken, input)
      .then((result) => {
        console.log({ result }, 'getDisease result');
        result.forEach((dis) => {
          dis.label = `${dis.name} - ${dis.code}`;
          dis.value = dis.code;
          dis.isDisabled = true;
        });
        // result.push({
        //   label: 'other',
        //   name: 'other',
        //   code: "OTHER",
        // });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  getDoctors = (input) => {
    return getDoctors(this.props.authToken, input)
      .then((result) => {
        console.log({ result }, 'getDoctors result');
        result.forEach((doc) => {
          doc.label = doc.doctorName;
          doc.value = doc.doctorId;
        });
        result.push({
          label: 'Enter Doctor Name',
          value: 'other',
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  getCenters = (input) => {
    return getCenters(this.props.authToken, input)
      .then((result) => {
        console.log({ result }, 'getDoctors result');
        result.forEach((cen) => {
          cen.label = cen.title;
          cen.data = {
            address: cen.address,
          };
          cen.value = cen.centerId;
        });
        result.push({
          label: 'Enter Center Details',
          value: 'other',
          data: {
            address: '',
          },
        });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };


  onDiseaseSelect = (option) => {
    console.log("Disease=>", { option, ref: this.diseaseSelectRef.current });
    let storeDiagnosis = []

    if (option) {
      if (option.find(e => e.name === 'other')) {
        this.setState({ addDiagnosisModal: true });
        return;
      }
      option.map((data) => {
        return storeDiagnosis.push({
          label: `${data.label}`,
          value: data.value,
          code: data.code
        })
      })

      this.setState({
        diagnosisValue: storeDiagnosis
      }, () => {
        console.log('diagnosisValue', this.state.diagnosisValue);
      });
      this.setState({
        probableDiagnosis: storeDiagnosis && storeDiagnosis
          .map((e) => {
            return e.label.slice(',');
          }).join(','),
        icdCode: option.code,
      }, () => {

        this.props.dispatch({
          type: 'SET_PROBABLE_DIAGNOSIS',
          payload: this.state.probableDiagnosis
        });
        this.props.dispatch({
          type: 'SET_ICD_CODE',
          payload: option.map((d) => {
            return d.code.slice(',')
          }).join(','),
        });
      })

    } else {
      this.diseaseSelectRef.current.state.defaultOptions = null;
    }
    // }
    // else{
    //   window.alert('You can not select more than 5 options')
    // }
  };

  OnSecondaryDiseaseSelect = (option) => {
    let storeSecondaryDiagnosis = []
    if (option) {
      if (option.find(data => data.name === 'other')) {
        this.setState({ addDiagnosisModal: true, secondaryDiagnosisModal: true });
        return;
      }
      option.map((data) => {
        return storeSecondaryDiagnosis.push({
          label: `${data.label}`,
          value: data.label,
          code: data.code
        })
      })
      this.setState({
        secondaryDiagnosisValue:
          storeSecondaryDiagnosis

      }, () => {
        console.log('---state', this.state.secondaryDiagnosisValue);
      })


      this.props.dispatch({
        type: 'SET_SECONDARY_DIAGNOSIS',
        payload: storeSecondaryDiagnosis.map((e) => { return e.label.slice(',') }).join(','),
      });
      this.props.dispatch({
        type: 'SET_SECONDARY_ICD_CODE',
        payload: storeSecondaryDiagnosis.map((e) => { return e.code.slice(',') }).join(','),
      });
    }
    else {
      this.secondaryDiseaseSelectRef.current.state.defaultOptions = null;
    }
  }

  onDocSelect = (option) => {
    console.log("Doctor=>", { option, ref: this.doctorSelectRef.current });

    if (option) {
      if (option.value === 'other') {
        this.setState({ addDoctorModal: true });
        return;
      }
      this.props.dispatch({
        type: 'SET_DOCTOR_NAME',
        payload: option.doctorName,
      });
      this.props.dispatch({
        type: 'SET_DOCTOR_ID',
        payload: option.doctorId,
      });
      this.setState({
        doctorValue: {
          label: option.doctorName,
          value: option.doctorId,
        },
      });
    } else {
      this.doctorSelectRef.current.state.defaultOptions = null;
    }
  };

  
  onCenterSelect = (option) => {
    console.log("center=>", { option, ref: this.centerSelectRef.current });
    // const labTests = [{...option}].concat(this.state.addedLabs);
    
    if (option) {
      if (option.value === 'other') {
        this.setState({ addCenterModal: true });
        return;
      }
      if(option.isBlacklisted){
        this.setState({isBlacklistedCentre:true})
        this.props.dispatch({
          type: 'SET_IS_CENTRE_BLACKLISTED',
          payload: true,
        });
        this.setState({
          centerValue: {
            label: option.title,
            value: option.centerId,
          },
        });
        this.props.dispatch({
          type: 'SET_CENTER_NAME',
          payload: option.title,
        });
        this.props.dispatch({
          type: 'SET_CENTER_ID',
          payload: option.centerId,
        });
        this.props.dispatch({
          type: 'SET_CENTER_ADDRESS',
          payload: option.address,
        });
        this.setState({
          centerValue: {
            label: option.title,
            value: option.centerId,
          },
        });
        this.props.dispatch({
          type:'SET_CENTRE_BLACKLISTED_NAME',
          payload:option.title
        })
        return ;
      }
      this.props.dispatch({
        type: 'SET_CENTER_NAME',
        payload: option.title,
      });
      this.props.dispatch({
        type: 'SET_CENTER_ID',
        payload: option.centerId,
      });
      this.props.dispatch({
        type: 'SET_CENTER_ADDRESS',
        payload: option.address,
      });
      this.setState({
        centerValue: {
          label: option.title,
          value: option.centerId,
        },
      });
      this.props.dispatch({
        type: 'SET_IS_CENTRE_BLACKLISTED',
        payload: false,
      });
      this.props.dispatch({
        type:'SET_CENTRE_BLACKLISTED_NAME',
        payload:'',
      })
      this.setState({isBlacklistedCentre:false})
    } else {
      this.centerSelectRef.current.state.defaultOptions = null;
    }
  };
  

  promiseDoctorOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getDoctors(input.toLowerCase()));
      }, 500);
    });
  };

  promiseDiseaseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getDisease(input.toLowerCase()));
      }, 500);
    });
  };

  promiseCenterOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getCenters(input.toLowerCase()));
      }, 500);
    });
  };
  addOtherDiagnosisValue = (value) => {
    let { diagnosisValue } = this.state;
    if (this.state.diagnosisValue.length < 5) {
      diagnosisValue.push({ label: value, value: value, code: "OTHERS", })
      this.onDiseaseSelect(diagnosisValue)
    }
    else {
      window.alert('You can not select more than 5 options')
    }
  }


  addDiseaseSelect = (value) => {
    const { secondaryDiagnosisModal } = this.state;

    if (!secondaryDiagnosisModal) {
      this.onDiseaseSelect({
        name: value,
        code: "OTHERS",
      });
      this.hideModal();

    }
    else {
      let { secondaryDiagnosisValue } = this.state;
      secondaryDiagnosisValue.push({ label: value, value: value, code: "OTHERS", })
      this.onDiseaseSelect(secondaryDiagnosisValue);
      this.setState({ secondaryDiagnosisModal: false });
      this.hideModal();

    }

  }

  render() {
    const probableDiagnosisLink = Link.state(
      this,
      'probableDiagnosis'
    ).onChange((e) => {
      console.log({ e }, 'probableDiagnosisLink');
      this.props.dispatch({
        type: 'SET_PROBABLE_DIAGNOSIS',
        payload: e,
      });
    });

    const doctorNameLink = Link.state(this, 'doctorName').onChange((e) => {
      console.log({ e }, 'doctorNameLink');
      this.props.dispatch({
        type: 'SET_DOCTOR_NAME',
        payload: e,
      });
    });




    const appointmentDateLink = Link.state(this, 'appointmentDate').onChange(
      (e) => {
        console.log({ e }, 'appointmentDateLink');

        this.props.dispatch({
          type: 'SET_APPOINTMENT_DATE',
          payload: e,
        });
      }
    );

    const centerNameLink = Link.state(this, 'centerName').onChange((e) => {
      console.log({ e }, 'centerNameLink');
      this.props.dispatch({
        type: 'SET_CENTER_NAME',
        payload: e,
      });
    });

    const centerAddressLink = Link.state(this, 'centerAddress').onChange(
      (e) => {
        console.log({ e }, 'centerAddressLink');
        this.props.dispatch({
          type: 'SET_CENTER_ADDRESS',
          payload: e,
        });
      }
    );
    console.log('Relative value', this.state.relativeValue)
    const CustomOption = (props) => {
      return (
        <components.Option {...props}>
          <div className="d-flex justify-content-between">
            <BoldText fontSize="14px" lineHeight="17px" color="#413958">
              {props.label}
            </BoldText>
            <Text fontSize="14px" lineHeight="17px" color="#6A57F5">
              {props.data.address}
            </Text>
          </div>
        </components.Option>
      );
    };
    // console.log('center Address', this.props.centerAddress);
    const generalHealthAdviceLink = Link.state(this, 'advice');

    return this.props.digitisationDetails.sourceType ===
    'cashless-vision' ? <DigitizeVisionPrescription key={this.props.digitisationDetails.sourceId} /> : (
      <OuterContainer>
        <BodyContainer>
          {this.props.digitisationDetails.sourceType ===
            'offline-consultations' || this.props.digitisationDetails.sourceType ===
            'cashless-dental' ? (
            <React.Fragment />
          ) : (
            <React.Fragment>
              <SectionContainer>
                <SectionTitle>
                  Consultations{' '}
                  <small>(Check for existing consultations)</small>
                </SectionTitle>
                <Select
                  key={this.props.digitisationRequestId}
                  options={this.props.pastAppointments.map((c) => {
                    c.value = c.prescriptionId;
                    c.label = `${c.prescriptionId}. ${c.doctorName} - ${c.date} - Patient: ${c.patientName} [${c.type}]`;
                    return c;
                  })}
                  isClearable={true}
                  onChange={this.setDigitisationConsultation}
                />
              </SectionContainer>
              <SectionContainer>
                {this.props.digitisationConsultation &&
                  this.props.digitisationConsultation.prescriptionUrl ? (
                  <a
                    href={this.props.digitisationConsultation.prescriptionUrl}
                    target="_blank"
                  >
                    View Prescription
                  </a>
                ) : null}
              </SectionContainer>
            </React.Fragment>
          )}
          {!this.props.digitisationConsultation ? (
            <>
              <SectionContainer>
                <SectionTitle>Probable Diagnosis(Primary)*</SectionTitle>
                {/* <StyledInputBox
                  valueLink={probableDiagnosisLink}
                  className="visit-input"
                  placeholder="eg. alopecia alcarta"
                  disabled={this.formDisable()}
                /> */}
                <AsyncSelect
                  ref={this.diseaseSelectRef}
                  isMulti
                  placeholder="Search Disease"
                  cacheOptions
                  isClearable={true}
                  value={this.state.diagnosisValue}
                  // defaultOptions={[
                  //   { label: 'other', code: 'OTHER', name: "other" },
                  // ]}
                  loadOptions={this.promiseDiseaseOptions}
                  onChange={(option, cb,) => this.onDiseaseSelect(option)}
                  isOptionDisabled={(option) => this.state.diagnosisValue.length > 0 ? option.isDisabled : false}

                />
              </SectionContainer>

              <SectionContainer>
                <SectionTitle>Probable Diagnosis(Secondary)</SectionTitle>
                {/* <StyledInputBox
                  valueLink={probableDiagnosisLink}
                  className="visit-input"
                  placeholder="eg. alopecia alcarta"
                  disabled={this.formDisable()}
                /> */}
                <AsyncSelect
                  ref={this.secondaryDiseaseSelectRef}
                  placeholder="Search Disease"
                  isMulti
                  cacheOptions
                  isClearable={true}
                  value={this.state.secondaryDiagnosisValue}
                  // defaultOptions={this.state.secondaryDiagnosisValue.length > 3 ?
                  //   secondaryDiagnosisDisabledMsg :
                  //   [
                  //     { label: 'other', code: 'OTHER', name: "other" },
                  //   ]
                  // }
                  loadOptions={this.promiseDiseaseOptions}
                  onChange={(option, cb) => this.OnSecondaryDiseaseSelect(option)}
                  isOptionDisabled={(option) => this.state.secondaryDiagnosisValue.length > 3 ? option.isDisabled : false}
                />
              </SectionContainer>

              {/* {this.props.digitisationDetails.sourceType ===
          'offline-consultations' ? "" :
          <>*/}
              <SectionContainer>
                <SectionTitle>Doctor Name*</SectionTitle>
                {/* <StyledInputBox
                  valueLink={doctorNameLink}
                  className="visit-input"
                  placeholder="Enter Doctor Name"
                  disabled={this.formDisable()}
                /> */}
                <AsyncSelect
                  ref={this.doctorSelectRef}
                  placeholder="Search Doctor"
                  cacheOptions
                  isClearable={true}
                  defaultOptions={[
                    { label: 'Enter Doctor Name', value: 'other', },
                  ]}
                  value={this.state.doctorValue}
                  loadOptions={this.promiseDoctorOptions}
                  onChange={(option, cb) => this.onDocSelect(option, cb)}
                />
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>Appointment Date*</SectionTitle>
                <StyledNormalInputBox
                  value={this.state.appointmentDate}
                  maxLength={10}
                  onChange={(e) => this.onAppointmentDateChange(e.target.value)}
                  className="visit-input"
                  placeholder="YYYY-MM-DD"
                  disabled={this.formDisable()}
                />
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>Prescription Validity Date*</SectionTitle>
                <SingleDatePicker
                  date= { this.state.prescriptionValidityDate ? moment(this.state.prescriptionValidityDate, 'YYYY-MM-DD') : null}
                  onDateChange={(date) => this.onPrescriptionValidityDateChange(date)}
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  id="visit-input-date" 
                  className="visit-input"
                  numberOfMonths={1}
                  daySize={50} 
                  noBorder={true} 
                  withPortal={true} 
                  disabled={this.formDisable()}
                  placeholder="YYYY-MM-DD"
                  displayFormat="YYYY-MM-DD"
                  style={{ 
                    width: '100%', 
                    boxSizing: 'border-box', 
                    borderBottom: '1px solid #cecece !important', 
                  }}
                />
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>Center Name* {this.state.isBlacklistedCentre?  
                <div className="flex mt-1">
                <label
                  style={{
                    borderRadius: '50%',
                    color: 'white',
                    fontSize: '9px',
                    border: '1px solid #EC6519',
                    fontWeight: 600,
                    width: '14px',
                    height: '14px',
                    backgroundColor: '#EC6519',
                    textAlign: 'center',
                    bottom: '2px',
                  }}
                >
                  i
                </label>
                <label
                  className="ml-1"
                  style={{
                    color: '#EC6519',
                    fontSize: '11px',
                    fontWeight: 600,
                  }}
                >
                  This Centre is blacklisted.
                </label>
              </div>:('')} </SectionTitle>
                {/* <StyledInputBox
                  valueLink={centerNameLink}
                  className="visit-input"
                  placeholder="Enter Center Name"
                /> */}
                <AsyncSelect
                  ref={this.centerSelectRef}
                  placeholder="Enter Center Name"
                  cacheOptions
                  isClearable={true}
                  value={this.state.centerValue}
                  defaultOptions={[{ label: 'Enter Center Details', value: 'other' }]}
                  components={{
                    Option: CustomOption,
                  }}
                  loadOptions={this.promiseCenterOptions}
                  onChange={(option, cb) => this.onCenterSelect(option, cb)}
                />
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>Center Address</SectionTitle>
                <input
                  // valueLink={centerAddressLink}
                  className="visit-input"
                  placeholder="Select Center Name"
                  value={
                    this.props.centerAddress ? this.props.centerAddress : ''
                  }
                  // disabled={this.formDisable()}
                  disabled={true}
                />
              </SectionContainer>
              <SectionContainer>
                <SectionTitle>Patient*</SectionTitle>
                <Select
                  isDisabled={this.formDisable() || this.state.disableUserToChangePatient}
                  key={this.props.digitisationRequestId}
                  value={this.state.relativeValue}
                  options={this.props.relatives.map((r) => {
                    return { value: r.relativeId, label: r.name };
                  })}
                  //options={options}
                  // isSearchable="true"
                  onChange={this.setRelativeId}
                />
              </SectionContainer>
              {/* </>
           } */}
            </>
          ) : (
            ''
          )}
          <DosageContainer>
            <input
              type="checkbox"
              disabled = { this.props.user.isDigitisationAdmin != 1 && this.props.digitisationDetails.status === 'complete'}
              onChange={this.opdCriticalChange}
              checked={this.state.isCritical}
              id="consentCheck"
            />
            <LabelText>Critical Illness</LabelText>
          </DosageContainer>

          {this.props.digitisationDetails.sourceType !== 'offline-consultations' && this.props.digitisationDetails.sourceType !== 'uploaded-labs' ? (
            <SectionContainer>
              <SectionTitle>Medicine Carts</SectionTitle>
              <div style={{
                maxHeight: '50vh',
                overflowY: 'scroll'
              }}>
                {//this.props.medicineCartItems.map(this.renderMedicineCartItem)
                  this.renderMedicineCartItem(this.props.cartItems)
                }
              </div>
              <StyledTappableCard onTap={() => this.openAddMedicineDialog()}>
                <TappableText>+ Add Medicines</TappableText>
              </StyledTappableCard>
            </SectionContainer>
          )
            : ('')}
          {this.props.digitisationDetails.sourceType !== 'offline-consultations' ?
            (
              <SectionContainer>
                <SectionTitle>Lab tests</SectionTitle>
                {this.props.labTests.length > 0 ? (
                  <AddedTestsContainer>
                    <AddedTestsInnerContainer>
                      {this.props.labTests.map(this.renderLabTest)}
                    </AddedTestsInnerContainer>
                  </AddedTestsContainer>
                ) : null}
                {this.props.digitisationDetails.status !== 'complete'? <StyledTappableCard onTap={() => this.openAddLabTestDialog()}>
                  <TappableText>+ Add Lab Tests</TappableText>
                </StyledTappableCard>:('')}
               
              </SectionContainer>) : ('')}

          <SectionContainer>
            <SectionTitle>General Health Advice</SectionTitle>
            {this.props.generalHealthAdvice.map(this.renderAdvices)}
            <AddAdviceContainer>
              <StyledInputBox
                valueLink={generalHealthAdviceLink}
                className="visit-input"
                placeholder="add an advice for patient"
                disabled={this.formDisable()}
              />
              <StyledTappable
                onTap={() => this.addAdvice()}
                disabled={this.state.advice.length > 0 ? false : true}
              >
                <TappableAdviceText>
                  + <TappableText>Add</TappableText>
                </TappableAdviceText>
              </StyledTappable>
            </AddAdviceContainer>
          </SectionContainer>
        </BodyContainer>
        {this.state.addDoctorModal && (
          <AddDoctorModal
            hideModal={() => this.hideModal()}
            addDoctorName={(value) => {
              this.onDocSelect({
                doctorName: value,
                doctorId: null,
              });
              this.hideModal();
            }}
          ></AddDoctorModal>
        )}
        {this.state.addDiagnosisModal && (
          <AddDiagnosisModal
            hideModal={() => this.hideModal()}
            addDiagnosis={(value) => {
              this.addDiseaseSelect(value)

            }
            }

          ></AddDiagnosisModal>
        )}
        {this.state.addCenterModal && (
          <AddCenterModal
            hideModal={() => this.hideModal()}
            addCenterDetails={(value) => {
              this.onCenterSelect({
                title: value.centerName,
                centerId: null,
                address: value.centerAddress,
              });
              this.hideModal();
            }}
          ></AddCenterModal>
        )}
        {this.state.medicinesModalVisible && (
          <AddMedicinesModal
            cartItems={this.props.cartItems}
            reloadCartItems={() => this.reloadCartItems()}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.editSubstituteMedicineModalVisible && (
          <EditMedicineModal
            reloadCartItems={() => this.reloadCartItems()}
            editMedicine = {this.state.editMedicine}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.labTestModalVisible && (
          <AddLabTestModal
            addLabTest={(body) => this.addLabTest(body)}
            removeLabTest={(body) => this.removeLabTest(body)}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.state.showSendPrescriptionSuccess && (
          <PrescriptionSentSuccessModal
            hideModal={() => this.hidePrescriptionSentSuccessModal()}
            message={this.state.successModalMessage}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const consultationId = state.consults.activeConsult.consultationId;
  const digitisationRequestId =
    state.consults.activeConsult.digitisationRequestId;
  let savedPrescription = state.savedPrescription[consultationId];
  const transcriberAccountActive = state.app.transcriberAccountActive;
  // let offlineDoctorAuthToken = null;
  if (transcriberAccountActive) {
    savedPrescription = state.savedPrescription[digitisationRequestId];
    // offlineDoctorAuthToken = state.consults.activeConsult.offlineInfo.authToken;
  }
  return {
    authToken: state.user.authToken,
    activeConsult: state.consults.activeConsult,
    prescriptionId: state.prescription.prescriptionId,
    medicines: state.prescription.medicines,
    labTests: state.prescription.labTests,
    cartItems: state.prescription.cartItems,
    probableDiagnosis: state.prescription.probableDiagnosis,
    secondaryDiagnosis: state.prescription.secondaryDiagnosis,
    generalHealthAdvice: state.prescription.generalHealthAdvice,
    user: state.user,
    prescription: state.prescription,
    savedPrescription,
    transcriberAccountActive,
    files: state.consults.activeConsult.files,
    filters: state.filters.filters,
    selectedSponsorId: state.selectedSponsorId,
    digitisationRequestId,
    relatives: state.prescription.relatives,
    digitisationConsultation: state.prescription.digitisationConsultation,
    pastAppointments: state.prescription.pastAppointments,
    digitisationDetails: state.digitisation,
    isCritical: state.isCritical,
    centerAddress: state.prescription.centerAddress,
  };
};

export default connect(mapStateToProps)(DigitizePrescriptionContainer);
