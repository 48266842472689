import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import {fetchMedicinesFromDimDrugs, fetchMedicinesFromElasticSearch, getServicablePartners, saveMedicineGroup } from '../../services/';
import { debounce } from '../../utils/debounce';
import MedicineList from './MedicineListModel';
import { BsCart3 } from 'react-icons/bs';
import MedicineNameBasedCartItemListModel from './MedicineNameBasedCartItemListModel';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;


const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  /* width: ${(props) => (props.width ? props.width : 'auto')}; */
`;

const StyledInputBoxSmall = styled(Input)`
  /* flex: 1; */
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  width: 8rem;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #4F34D2' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#4F34D2' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
  width:100%;
`;

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: '1rem',
};


const inputStyle = {
  width: '100%',
  padding: '0.5rem',
  marginTop: '1rem',
  borderRadius: '0.25rem',
  border: '1px solid #ccc',
  fontSize: '14px',

};


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),

};

const providerImageMapping = {
  '7': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/pharmeasy_logo.png',
  '1': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/apollo-logo.png',
  '5': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/1mg-logo.png',
  '9': 'https://visit-public.s3.ap-south-1.amazonaws.com/pharmacy/wellness_logo.png'
}

const pharmacyIdNameMapping = {
  '5': '1mg',
  '1': 'Apollo',
  '7': 'Phameasy',
  '9': 'Wellness Forever'
}

const medicineCardStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'space-between',
  gap: '1rem',
  width: '100%',
  padding: '0.5rem',
  borderRadius: '12px',
  margin: '1px',
  alignItems: 'center',
  border:'0.5px solid lightgray'
};

const medicineNameStyles = {
  display: 'flex', justifyContent: 'flex-start',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  letterSpacing: '0.25px',
  textAlign: 'left',
  textTransform: 'capitalize',
  color: '#0F0B28',
  overflowX: 'scroll',
  textOverflow: 'ellipsis',
  lineHeight: '130%',
};

const medicinePackStyles = {
  display: 'flex', justifyContent: 'flex-start',
  color: '#767787',
  fontWeight: '600',
  fontSize: '9px',
  textAlign: 'left',
  letterSpacing: '0.25px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const providerLogoContainerStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  minWidth: '40px',
  alignItems:'center',
};

const providerLogoStyles = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: '1px solid #E4E4E4'
};


class AddMedicinesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineName: '',
      lastMedicines: [],
      genericName: '',
      notes: '',
      mealPref: '',
      durationValue: '',
      dosage: '',
      duration: props.prescriptions.durations[0],
      dimDrugId: '',
      coveredUnderOpd: true,
      otherDosage: '',
      prescribedQuantity: '',
      isSubstitute: false,
      addSubstitute: false,
      medicineDosageDurationTabVisible: false,
      medicineDrugListTabVisible: true,
      prescribedDrugId: '',
      prescribedMedNameInRx: '',
      drugsMapping: [],
      medicines: [],
      selectedGroupOfMedicines: {},
      cartItems: props.cartItems,
      addOnTabVisible: false,
      notAvailablePartnerIds: [],
      addOnSubTabVisible: {
        1: false,
        5: false,
        7: false
      },
      addOnMedicineSelectedOption: null,
      addOnPartnerId: 0,
      addOnMedicines: {},
      notAvailableMedicines: {},
      prescribedDrugName: null,
      showNotAvailableTab: false,
      availableDrugs: [],
      searchedDrugName:null,
      isSearchingMedicines: false,
      servicablePartners: []
    };

    this.dosageSelectRef = React.createRef();
    this.durationSelectRef = React.createRef();
    this.debounceSearch = debounce(
      (input, callback) => this.promiseOptions(input, callback),
      500
    );

    this.dosageOptions = [];
    this.durationOptions = [];
    const { dosages, durations } = props.prescriptions;
    dosages.map((dosage) => {
      this.dosageOptions.push({
        label: dosage,
        value: dosage,
      });
    });
    durations.map((duration) => {
      this.durationOptions.push({
        label: duration,
        value: duration,
      });
    });
  }


  clearFields = () => {
    this.setState({
      medicineName: '',
      lastMedicines: [],
      genericName: '',
      notes: '',
      mealPref: '',
      durationValue: '',
      dosage: '',
      duration: this.props.prescriptions.durations[0],
      dimDrugId: '',
      coveredUnderOpd: true,
      otherDosage: '',
      prescribedQuantity: '',
      isSubstitute: false,
      addSubstitute: false,
      medicineDosageDurationTabVisible: false,
      medicineDrugListTabVisible: true,
      prescribedDrugId: '',
      prescribedMedNameInRx: '',
      drugsMapping: [],
      medicines: [],
      selectedGroupOfMedicines: {},
      // cartItems: this.props.cartItems,
      addOnTabVisible: false,
      notAvailablePartnerIds: [],
      addOnSubTabVisible: {
        1: false,
        5: false,
        7: false
      },
      addOnMedicineSelectedOption: null,
      addOnPartnerId: 0,
      addOnMedicines: {},
      notAvailableMedicines: {},
      prescribedDrugName: null,
      showNotAvailableTab: false,
      availableDrugs: [],
    });
    console.log(this.dosageSelectRef.current, 'reference');
  };

  selectMealPref = (mealPref) => {
    if (this.state.mealPref === mealPref) {
      this.setState({ mealPref: '' });
    } else {
      this.setState({ mealPref });
    }
  };

  addOnMedicineSelectMealPref = (id, mealPref) => {

    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].mealPref = mealPref
    this.setState({
      addOnMedicines: addOnMedicines
    });

  }
  onDosageSelect = (option) => {
    this.setState({
      dosage: option.value,
    });
  };

  onAddOnMedicineDosageSelect = (id, option) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].dosageOption = option;
    addOnMedicines[id].dosage = option.value;
    this.setState({
      addOnMedicines: addOnMedicines
    });
  };

  setAddOnMedicineDurationValue = (id, value) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].duration = value;
    addOnMedicines[id].durationValue = value;

    this.setState({
      addOnMedicines: addOnMedicines
    });
  }

  setAddOnMedicineOtherDosageValue = (id, value) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].otherDosage = value
    this.setState({
      addOnMedicines: addOnMedicines
    });
  }

   setAddOnMedicinePrescribedQuantityValue = (id, value) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].prescribedQuantity = value
    this.setState({
      addOnMedicines: addOnMedicines
    });
   }

  onMedicineSelect = (option) => {
    if (option) {
      this.setState({
        medicineName: option.value ? option.value : '',
        genericName: option.name ? option.generic_name : '',
        dimDrugId: option.dimDrugId,
      });
    } else {
      this.setState({
        medicineName: '',
        genericName: '',
        dimDrugId: '',
      });
    }
  };

  handleAddAddOn = (id) => {
    const addOnSubTabVisible = this.state.addOnSubTabVisible;
    Object.keys(addOnSubTabVisible).forEach((pharmacyId) => {
      if (pharmacyId != id) {
        addOnSubTabVisible[pharmacyId] = false;
      }
    });
    addOnSubTabVisible[id] = addOnSubTabVisible[id] ? false : true;
    const addOnMedicines = this.state.addOnMedicines;
    if (!addOnMedicines[id]) {
      addOnMedicines[id] = {
        duration: 0,
        durationValue: '',
        dosage: 0,
        durationOption: this.durationOptions[0],
        name: '',
        isSubstitute: addOnMedicines[id]?.isSubstitute || false
      };
    }
    this.setState({
      addOnSubTabVisible: addOnSubTabVisible,
      addOnPartnerId: id,
      addOnMedicines: addOnMedicines
    });
  }

  handleShowNotAvailableTab = () => { 
    this.clearFields();
    this.setState({
      showNotAvailableTab: !this.state.showNotAvailableTab
    });
    
  }

  handleIsSubstituteMedicine = () => {
    this.setState({
      prescribedDrugName: null,
      isSubstitute: !this.state.isSubstitute
    });
  }

  handleAddOnIsSubstituteMedicine = (id) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].isSubstitute = !addOnMedicines[id].isSubstitute
    this.setState({
      addOnMedicines: addOnMedicines
    });
  }

  handleClearAddOn = (id) => {
    const addOnSubTabVisible = this.state.addOnSubTabVisible;
    addOnSubTabVisible[id] = false;
    const addOnMedicines = this.state.addOnMedicines;
    if (addOnMedicines[id]) {
      addOnMedicines[id] = {
        duration: 0,
        durationValue: '',
        dosage: 0,
        durationOption: this.durationOptions[0],
        name: '',
        isSubstitute:false
      };
    }
    if (addOnMedicines[id]) {
      addOnMedicines[id].isSubstitute = false
    }
    this.setState({
      addOnSubTabVisible: addOnSubTabVisible,
      addOnPartnerId: id,
      addOnMedicines: addOnMedicines
    });
  }

  handleClickOnAddToCart = (medicineGroup, removedDrugIds) => {
    medicineGroup.providers = medicineGroup.providers.filter((medicine) => !removedDrugIds.includes(medicine.drugId));
    medicineGroup.notAvailableProviders = medicineGroup.providers.filter((medicine) => !this.state.availableDrugs.includes(medicine.drugId));
    medicineGroup.providers = medicineGroup.providers.filter((medicine) => this.state.availableDrugs.includes(medicine.drugId));
    const partnerIds = medicineGroup.providers.map((medicine) => medicine.partnerId)
    if (medicineGroup.providers.length < Object.keys(pharmacyIdNameMapping).length) {
      const partnerIdsNotAvailable = Object.keys(pharmacyIdNameMapping).filter((id) => !partnerIds.includes(id));
      this.setState({
        addOnTabVisible: true,
        notAvailablePartnerIds: partnerIdsNotAvailable
      });
    }
    this.setState({
      selectedGroupOfMedicines: medicineGroup,
      medicineDosageDurationTabVisible: true,
      medicineDrugListTabVisible: false,
    });
  };

  handleClickOnNotFound = () => {
    if (this.state.prescribedDrugName.length == 0) return;
    const medicineGroup = this.state.selectedGroupOfMedicines;
    const hashCode = (str, num) =>
      (str.split('').reduce((hash, char) => (hash << 5) - hash + char.charCodeAt(0), num) >>> 0);
    medicineGroup.partnerItemCode = hashCode(this.state.prescribedDrugName, this.props.digitisationDetails.digitisationRequestId);
    medicineGroup.name = this.state.prescribedDrugName;
    this.setState({
      selectedGroupOfMedicines: medicineGroup
    });  
    this.clearFields();
    this.addItemToCart(this.state.selectedGroupOfMedicines, this.state.addOnMedicines);
  };

  onAddToCartSelectHandle = (option) => {
    this.setState({
      duration: option.value,
    });
  }

  onDurationSelect = (option) => {
    this.setState({
      duration: option.value,
    });
  };

  onAddOnMedicineDurationSelect = (id, option) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].durationType = option.value;
    addOnMedicines[id].durationOption = option;
    this.setState({
      addOnMedicines: addOnMedicines
    });
  };

  handleTemplateName = (e) => {
    this.setState({ templateName: e.target.value });
  }

  loadAddOnOptions = (input, id, callback) => {
    fetchMedicinesFromDimDrugs(this.props.authToken, input.toLowerCase(), id)
      .then(data => {
        console.log(data);
        const options = data.map(item => ({
          value: item.name,
          label: this.medicineLabel(item),
          dimDrugId: item.drugId,
          packSize: item.packSize,
          packUnit: item.packUnit
        }));
        if (typeof callback === 'function') {
          callback(options);
        }
      });
  };

  medicineLabel = (medicine) => {
    const checkMedAvailability = medicine.hasOwnProperty("isAvailable");
    let availabilityMsg = '';
    if (checkMedAvailability) {
      availabilityMsg = medicine.isAvailable ? 'Available' : 'Not available';
    }
    return (
      <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px', textAlign: 'left' }}>
        <span style={{ fontWeight: 'normal' }}>{medicine.name}</span>
        <span style={{ color: medicine.isAvailable ? 'green' : '#FF754C', marginLeft: '10px' }}>
          {}
        </span>
        <br/>
        <span style={{ fontStyle: 'italic', fontSize: '10px', color: "gray" }}>{medicine.packSize?.toUpperCase()} {medicine.packUnit?.toUpperCase()}</span>
      </div>
    );
  };

  setSelectedAddOnOption = (id, option) => {
    const addOnMedicines = this.state.addOnMedicines;
    addOnMedicines[id].name = option.value;
    addOnMedicines[id].packSize = option.packSize;
    addOnMedicines[id].packUnit = option.packUnit;
    addOnMedicines[id].dimDrugId = option.dimDrugId;
    addOnMedicines[id].addOnMedicineSelectedOption = option;
    this.setState({
      addOnMedicines: addOnMedicines
    });
  }

  getMedicinesFromElasticSearch = async (input) => {
    this.setState({ isSearchingMedicines: true, searchedDrugName: input });
    this.clearFields();
    const response = await fetchMedicinesFromElasticSearch(this.props.authToken, input.toLowerCase());
    const medicines = response.drugs;
    const availableDrugs = response.availableDrugs;
    this.setState({
      medicineDosageDurationTabVisible: false,
      medicineDrugListTabVisible: true,
      isSearchingMedicines: false,
    });
    const sortedMedicines = medicines.sort((a, b) => b.providers.length - a.providers.length);
    this.setState({
      medicines: sortedMedicines,
      availableDrugs: availableDrugs
    });
  };

  getMedicinesFromElasticSearchDebounced = debounce(this.getMedicinesFromElasticSearch, 500);

  onMedicineSearchChange = (e) => {
    this.getMedicinesFromElasticSearchDebounced(e.target.value);
  }

  getServicablePartners = async () => {
    this.clearFields();
    const response = await getServicablePartners(this.props.authToken);
    this.setState({
      servicablePartners : response
    });
  };

  handlePrescribedMedicine = (e) => {
    const input = e.target.value;
    this.setState({
      prescribedDrugName: input
    });
  };

  addItemToCart = (medicineGroup, addOnMedicines) => {
    medicineGroup.digitisationId = this.props.digitisationDetails.digitisationRequestId;
    medicineGroup.providers = medicineGroup.providers ? medicineGroup.providers : new Array();;
    if (this.state.isSubstitute) {
      medicineGroup.name = this.state.prescribedDrugName;
    }
    const cartMedicines = new Array();
    const addedPartnerMedicines = new Array();
    if (addOnMedicines) { 
      for (const companyId in addOnMedicines) {
        const medicine = addOnMedicines[companyId];
        if (Number(medicine.dimDrugId)) {
          medicine.partnerId = companyId || '';
          medicine.name = medicine.name || '';
          medicine.genericName = this.state.genericName || '';
          medicine.dosage = this.state.dosage || '';
          medicine.prescribedQuantity = this.state.dosage === 'Other' ? this.state.prescribedQuantity : '';
          medicine.duration = this.state.durationValue || '';
          medicine.durationType = this.state.duration || '';
          medicine.meal = this.state.mealPref || '';
          medicine.notes = this.state.dosage === 'Other' ? this.state.otherDosage || '' : this.state.notes || '';
          medicine.dimDrugId = medicine.dimDrugId;
          medicine.drugId = medicine.dimDrugId;
          medicine.coveredUnderOpd = this.state.coveredUnderOpd;
          medicine.prescribedMedInRx = this.state.prescribedDrugName || '';
          medicine.isSubstitute = medicine.isSubstitute;
          medicine.isAvailable = true;
          medicine.prescribedDrugId = medicine.prescribedDrugId || '';
          cartMedicines.push(medicine);
          addedPartnerMedicines.push(companyId)
        }
      }
    }

    if (medicineGroup && medicineGroup.providers && Array.isArray(medicineGroup.providers)) { 
      medicineGroup.providers.forEach((medicine) => {
        if (!addedPartnerMedicines.includes(medicine.partnerId)) {
          if (Number(medicine.drugId)) { 
            medicine.name = medicine.name || '';
            medicine.genericName = this.state.genericName || '';
            medicine.dosage = this.state.dosage || '';
            medicine.prescribedQuantity = this.state.dosage === 'Other' ? this.state.prescribedQuantity : null;
            medicine.duration = this.state.durationValue || '';
            medicine.durationType = this.state.duration || '';
            medicine.meal = this.state.mealPref || '';
            medicine.notes = this.state.dosage === 'Other' ? this.state.otherDosage : this.state.notes;
            medicine.dimDrugId = medicine.partnerItemCode;
            medicine.coveredUnderOpd = this.state.coveredUnderOpd;
            medicine.prescribedMedInRx = this.state.prescribedMedNameInRx;
            medicine.isSubstitute = this.state.isSubstitute;
            medicine.isAvailable = true;
            medicine.prescribedDrugId = this.state.prescribedDrugId;
            addedPartnerMedicines.push(medicine.partnerId);
            cartMedicines.push(medicine);
          }
        }
      });
    }

    if (medicineGroup && medicineGroup.notAvailableProviders && Array.isArray(medicineGroup.notAvailableProviders)){ 
      medicineGroup.notAvailableProviders.forEach((medicine) => {
        if (!addedPartnerMedicines.includes(medicine.partnerId)) {
          if (Number(medicine.drugId)) { 
            medicine.name = medicine.name || '';
            medicine.genericName = this.state.genericName || '';
            medicine.dosage = this.state.dosage || '';
            medicine.prescribedQuantity = this.state.dosage === 'Other' ? this.state.prescribedQuantity : null;
            medicine.duration = this.state.durationValue || '';
            medicine.durationType = this.state.duration || '';
            medicine.meal = this.state.mealPref || '';
            medicine.notes = this.state.dosage === 'Other' ? this.state.otherDosage : this.state.notes;
            medicine.dimDrugId = medicine.partnerItemCode;
            medicine.coveredUnderOpd = this.state.coveredUnderOpd;
            medicine.prescribedMedInRx = this.state.prescribedMedNameInRx;
            medicine.isSubstitute = this.state.isSubstitute;
            medicine.isAvailable = false;
            medicine.prescribedDrugId = this.state.prescribedDrugId;
            addedPartnerMedicines.push(medicine.partnerId);
            cartMedicines.push(medicine);
          }
        }
      });
    }

    /**
     * DO NOT REMOVE 
     * THIS SECTION IS COMMENTED ON SHAURYA'S INSTRUCTION TO AVOID DOCTOR TO ADD DIFFERENT DOSAGE
     for (const companyId in addOnMedicines) {
      const medicine = addOnMedicines[companyId];
      if (medicine.dosage && medicine.duration) {
        medicine.partnerId = companyId || '';
        medicine.name = medicine.name || '';
        medicine.genericName = this.state.genericName || '';
        medicine.dosage = medicine.dosage || '';
        medicine.prescribedQuantity = medicine.dosage === 'Other' ? medicine.prescribedQuantity : '';
        medicine.duration = medicine.duration || '';
        medicine.durationType = medicine.durationType || '';
        medicine.meal = medicine.mealPref || '';
        medicine.notes = medicine.dosage === 'Other' ? medicine.otherDosage || '' : medicine.notes || '';
        medicine.dimDrugId = medicine.dimDrugId;
        medicine.drugId = medicine.dimDrugId;
        medicine.coveredUnderOpd = this.state.coveredUnderOpd;
        medicine.prescribedMedInRx = medicine.prescribedMedNameInRx || '';
        medicine.isSubstitute = true;
        medicine.isAvailable = true;
        medicine.prescribedDrugId = medicine.prescribedDrugId || '';
        medicineGroup.providers.push(medicine);
      }
    }
     */
    let notAvailablePartnerIds = new Array(1, 5, 7);
    for (const partnerId of addedPartnerMedicines) {
      notAvailablePartnerIds = notAvailablePartnerIds.filter(ids => ids != partnerId);
    }
    for (const partnerId of notAvailablePartnerIds) {
      let isMedicineAlreadyAdded = false;
      for (const medicine of medicineGroup.providers) {
        if (medicine.partnerId == partnerId) {
          medicine.partnerId = medicine.partnerId || '';
          medicine.name = medicine.name || '';
          medicine.genericName = this.state.genericName || '';
          medicine.dosage = this.state.dosage || '';
          medicine.prescribedQuantity = this.state.dosage === 'Other' ? this.state.prescribedQuantity : null;
          medicine.duration = this.state.durationValue || '';
          medicine.durationType = this.state.duration || '';
          medicine.meal = this.state.mealPref || '';
          medicine.notes = this.state.dosage === 'Other' ? this.state.otherDosage : this.state.notes;
          medicine.dimDrugId = medicine.partnerItemCode;
          medicine.coveredUnderOpd = this.state.coveredUnderOpd;
          medicine.prescribedMedInRx = this.state.prescribedMedNameInRx;
          medicine.isSubstitute = medicine.isSubstitute;
          medicine.isAvailable = false;
          medicine.prescribedDrugId = this.state.prescribedDrugId;
          cartMedicines.push(medicine);
          isMedicineAlreadyAdded = true;
          break;
        }
      }
      if (!isMedicineAlreadyAdded) { 
        cartMedicines.push({
          partnerId: partnerId,
          name: this.state.prescribedDrugName || medicineGroup.name || '',
          genericName: this.state.prescribedDrugName || medicineGroup.name || '',
          dosage: '',
          prescribedQuantity: '',
          duration: '',
          durationType: '',
          meal: '',
          notes: '',
          dimDrugId: this.state.prescribedDrugName || medicineGroup.name || '',
          drugId: this.state.prescribedDrugName || medicineGroup.name || '',
          coveredUnderOpd: this.state.coveredUnderOpd,
          prescribedMedInRx: this.state.prescribedDrugName || medicineGroup.name || '',
          isSubstitute: false,
          prescribedDrugId: this.state.prescribedDrugName || medicineGroup.name || '',
          isAvailable: false,
        });
      }
    }
    medicineGroup.providers = cartMedicines;
    const requestBody = {
      cartItem: medicineGroup,
    };
    saveMedicineGroup(this.props.authToken, requestBody)
      .then((result) => {
        this.setState({
          cartItems: result,
          medicineDosageDurationTabVisible: false,
          medicineDrugListTabVisible: true,
        });
      })
      .catch((err) => {
        console.log({ err }, 'error in save cart items');
      });

  }
  opdCheckBoxChange = (ev) => {
    this.setState({
      coveredUnderOpd: ev.currentTarget.checked,
    });
  };


  componentDidMount() {
     setTimeout(() => {
      this.getServicablePartners();
    }, 2000);
  }


    render() {
    const medicineNameLink = Link.state(this, "medicineName");
    const genericNameLink = Link.state(this, 'genericName');
    const notesLink = Link.state(this, 'notes');
    const durationValue = Link.state(this, 'durationValue');
    const prescribedQuantity = Link.state(this, 'prescribedQuantity');
    const otherDosage = Link.state(this, 'otherDosage');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Prescription</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.reloadCartItems();
                      this.clearFields();
                      this.props.hideModal();
                    }}
                  >
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={() => {
                  this.props.reloadCartItems();
                  this.clearFields();
                  this.props.hideModal();
                }}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>
            <Body>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: '1' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'flex-start',alignItems:'baseline', alignContent:'baseline'}}>
                    <LabelText style={{
                      fontWeight: 'bolder',
                      fontSize: '16px'
                    }}>Add Medicine</LabelText>
                    {this.state.showNotAvailableTab ? <p style={{
                      marginLeft: '2rem',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      fontWeight: 500,
                      fontSize: '11px',
                      lineHeight: '130%',
                      letterSpacing: '0.25px',
                      color: '#767787'
                    }}><p
                      style={{
                        color: '#ff754c',
                        fontSize: '12px',
                        fontWeight: 500,
                        bottom: '0'
                        // Add some space between text and dot
                      }}
                    >
                        Note :
                      </p>&nbsp; You are adding not available medicine</p> :
                      <p style={{
                        marginLeft:'2rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        fontWeight: 500,
                        fontSize: '11px',
                        lineHeight: '130%',
                        letterSpacing: '0.25px',
                        color: '#767787'
                      }}><p
                        style={{
                          color: '#ff754c',
                          fontSize: '12px',
                          fontWeight: 500,
                          bottom: '0'
                          // Add some space between text and dot
                        }}
                      >
                          Note :
                        </p>&nbsp; Please try to search for prescribed medicine before searching substitute</p>  
                    }
                  </div>
                   
                  <div style={providerLogoContainerStyles}>
                    <LabelText style={{
                      fontWeight: 'bolder',
                      fontSize: '16px',
                      marginRight:'1rem'
                    }}>Partners In Service</LabelText>
                    {this.state.servicablePartners.map((item) => {
                      return <img src={providerImageMapping[item]} style={{ ...providerLogoStyles, zIndex: 1 }} />;
                    })}
                  </div>
                  {!this.state.showNotAvailableTab  && <input
                    type="text"
                    style={inputStyle}
                    placeholder="Search medicine here"
                    disabled={this.state.showNotAvailableTab}
                    autoFocus={!this.state.showNotAvailableTab}
                    onChange={this.onMedicineSearchChange} />
                  }
                  {(this.state.showNotAvailableTab || this.state.isSubstitute)  && <input
                    type="text"
                    style={inputStyle}
                    autoFocus={this.state.showNotAvailableTab}
                    disabled={!this.state.showNotAvailableTab && !this.state.isSubstitute}
                    placeholder="Enter prescribed drug name here"
                    value={this.state.prescribedDrugName}
                    onChange={this.handlePrescribedMedicine} />}
                  {this.state.medicineDrugListTabVisible && (
                    <div>
                      <div style={{ flex: '1', padding: '1rem' }}>
                        <InputContainer>
                          <AgeContainer>
                            {this.props.digitisationDetails.sourceType !== 'reimbursements' ?
                              <div>
                                <div style={containerStyle} >

                                  <div style={{ position: 'relative', top: '100%', width: '100%',height:'58vh', left: 0,  overflowY: 'scroll', background: '' }}>
                                    {!this.state.showNotAvailableTab ?
                                      <MedicineList
                                        loader={this.state.isSearchingMedicines}
                                        medicines={this.state.medicines}
                                        searchedDrugName={this.state.searchedDrugName}
                                        availableDrugs={this.state.availableDrugs}
                                        handleClickOnAddToCart={this.handleClickOnAddToCart} /> :
                                      <center style={{ marginTop: '20%' }}>
                                        {this.state.showNotAvailableTab &&
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              marginTop: '2px'
                                            }}>
                                            <button
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '8px 16px 8px 12px',
                                                gap: '10px',
                                                fontSize:'12px',
                                                color: this.state.prescribedDrugName?.length > 0 ? 'white' : '#714FFF',
                                                fontWeight:'bolder',
                                                borderRadius: '12px',
                                                border: '1px solid #714FFF',
                                                background: this.state.prescribedDrugName?.length >0 ? '#714FFF': 'white',

                                              }}
                                              onClick={() => {
                                                this.handleClickOnNotFound();
                                              }}
                                            >
                                              <BsCart3></BsCart3> Add To Cart
                                            </button>
                                          </div>
                                        }
                                        </center>
                                    }
                                    
                                  </div>

                                </div>
                              </div> : (
                                <InputContainer>
                                  <React.Fragment>
                                    <AgeContainer>
                                      <StyledInputBox
                                        type="text"
                                        className="visit-input"
                                        valueLink={medicineNameLink}
                                        placeholder="Enter Prescribed Medicine Name"
                                      />
                                    </AgeContainer>
                                  </React.Fragment>
                                </InputContainer>
                              )
                            }
                          </AgeContainer>
                        </InputContainer>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', fontSize: '12px', color: '#714FFF' }}> {!this.state.showNotAvailableTab && <p> Cannot find neither original medicine nor substitute medicine?</p>}  <button
                        style={{
                          marginLeft:'5px',
                          padding: '8px 14px 8px 12px',
                          fontWeight: 'bold',
                          color: this.state.showNotAvailableTab ? 'white' : '#714FFF',
                          borderRadius: '12px',
                          border: '1px solid #714FFF',
                          background: this.state.showNotAvailableTab ? '#714FFF' : 'white',
                          width: '15rem',
                        }}
                        onClick={() => {
                          this.handleShowNotAvailableTab();
                        }}
                      >
                        {this.state.showNotAvailableTab ?  'Search Medicine': 'Add Manually'}
                      </button></div>
                     
                    </div>
                  )}
                  {this.state.medicineDosageDurationTabVisible && <div style={{
                    border: '1px solid #E4E4E4', borderRadius: '12px', padding: '0.5rem', marginTop: '1rem', maxHeight: '61vh',
                    overflowY: 'scroll'
                  }}>
                    <div style={{ ...medicineCardStyles }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                        }} >
                        <div style={providerLogoContainerStyles}>
                          {this.state.selectedGroupOfMedicines.providers.map((item) => {
                            return <img src={providerImageMapping[item.partnerId]} style={{ ...providerLogoStyles, zIndex: 1 }} />;
                          })}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 'auto', marginLeft:'1rem'}}>
                        <div style={{ ...medicineNameStyles, alignSelf: 'flex-start' }}>{this.state.selectedGroupOfMedicines.name.toUpperCase()}</div>
                        <div style={{ ...medicinePackStyles, alignSelf: 'flex-start' }}>{this.state.selectedGroupOfMedicines.packSize} {this.state.selectedGroupOfMedicines.packUnit.toUpperCase()}</div>
                      </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', marginLeft: '1rem' }}>
                          <input
                            style={{marginLeft:'1rem'}}
                            type="checkbox"
                            onChange={() => this.handleIsSubstituteMedicine()}
                            checked={this.state.isSubstitute}
                            value={''}
                          />
                          <b>Substitute</b>
                        <input
                          style={{ marginLeft: '1rem' }}
                          type="checkbox"
                          onChange={this.opdCheckBoxChange}
                          defaultChecked={this.state.coveredUnderOpd}
                          value={''}
                          id="consentCheck"
                        />
                        <b>Covered Under OPD</b>
                      </div>
                    </div>
                    {this.state.coveredUnderOpd == true  && <div>
                      <InputContainer>
                        <StyledInputBox
                          type="text"
                          className="visit-input"
                          valueLink={genericNameLink}
                          placeholder="Generic Name"
                        />
                        <StyledTappableCard
                          onTap={() => this.selectMealPref('before')}
                          isSelected={this.state.mealPref === 'before' ? true : false}
                        >
                          <TappableText
                            isSelected={this.state.mealPref === 'before' ? true : false}
                          >
                            Before food
                          </TappableText>
                        </StyledTappableCard>
                        <StyledTappableCard
                          onTap={() => this.selectMealPref('after')}
                          isSelected={this.state.mealPref === 'after' ? true : false}
                        >
                          <TappableText
                            isSelected={this.state.mealPref === 'after' ? true : false}
                          >
                            After food
                          </TappableText>
                        </StyledTappableCard>
                      </InputContainer>
                      <InputContainer>
                        <AgeContainer>
                          <LabelText>Dosage</LabelText>
                          <SelectElement
                            options={this.dosageOptions}
                            ref={this.dosageSelectRef}
                            styles={customStyles}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary50: '#d5d5d5',
                                primary25: '#f5f5f5',
                                primary: '#4F34D2',
                              },
                            })}
                            placeholder="Select Dosage"
                            onChange={(option) => this.onDosageSelect(option)}
                          />
                        </AgeContainer>
                        <AgeContainer>
                          <LabelText>Duration</LabelText>
                          <DosageContainer>
                            <StyledInputBoxSmall
                              type="text"
                              className="visit-input"
                              valueLink={durationValue}
                              placeholder="0"
                            />
                            <SelectElement
                              options={this.durationOptions}
                              ref={this.durationSelectRef}
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary50: '#d5d5d5',
                                  primary25: '#f5f5f5',
                                  primary: '#4F34D2',
                                },
                              })}
                              defaultValue={this.durationOptions[0]}
                              placeholder="Days, Weeks, Months..."
                              onChange={(option) => this.onDurationSelect(option)}
                            />
                          </DosageContainer>
                        </AgeContainer>
                      </InputContainer>
                      <InputContainer>
                        {this.state.dosage === 'Other' ? (
                          <React.Fragment>
                            <AgeContainer>
                              <StyledInputBox
                                type="text"
                                className="visit-input"
                                valueLink={otherDosage}
                                placeholder="Dosage Description"
                              />
                            </AgeContainer>
                            <AgeContainer>
                              <StyledInputBox
                                type="number"
                                className="visit-input"
                                valueLink={prescribedQuantity}
                                placeholder="Prescribed Quantity"
                              />
                            </AgeContainer>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </InputContainer>
                      {!this.state.dosage || this.state.dosage !== 'Other' ?
                        <InputContainer>
                          <StyledInputBox
                            type="text"
                            className="visit-input"
                            valueLink={notesLink}
                            placeholder="Notes (optional)"
                          />
                        </InputContainer>
                        :
                        null}
                    </div>
                    }
                    {this.state.addOnTabVisible && this.state.notAvailablePartnerIds.map((id) => {
                      return (
                        <div style={{
                          borderRadius: '10px',
                          marginBottom:'10px'
                        }}>
                          
                          <div style={{ ...medicineCardStyles, flexDirection: 'row' }}>
                            <img src={providerImageMapping[id]} style={{ ...providerLogoStyles }} />
                            {!this.state.addOnSubTabVisible[id] &&
                              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                                <div style={{ ...medicineNameStyles, alignSelf: 'flex-start' }}>{
                                  this.state.addOnMedicines[id]?.name.toUpperCase() || this.state.selectedGroupOfMedicines.name.toUpperCase()
                                }</div>
                                <div style={{ ...medicinePackStyles, alignSelf: 'flex-start' }}>{this.state.addOnMedicines[id]?.packSize} {this.state.addOnMedicines[id]?.packUnit}</div>
                                {!this.state.addOnMedicines[id]?.name && <div style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  fontWeight: 500,
                                  fontSize: '10px',
                                  lineHeight: '130%',
                                  letterSpacing: '0.25px',
                                  textTransform: 'uppercase',
                                  color: '#767787'
                                }}>Not Available</div>}
                              </div>
                            }
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '2px',
                              }}
                            >
                              {
                                this.state.addOnMedicines[id]?.name &&
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '1rem' }}>
                                <input
                                  style={{ marginLeft: '1rem' }}
                                  type="checkbox"
                                  onChange={() => this.handleAddOnIsSubstituteMedicine(id)}
                                  checked={this.state.addOnMedicines[id]?.isSubstitute}
                                  value={''}
                                  />
                                <b>Substitute</b>
                              </div>
                              }
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '8px 16px 8px 12px',
                                  gap: '10px',
                                  color: this.state.addOnSubTabVisible[id] ? 'white' : '#714FFF',
                                  borderRadius: '12px',
                                  border: '1px solid #714FFF',
                                  background: this.state.addOnSubTabVisible[id] ? '#714FFF' : 'white',
                                  width: '11rem',
                                }}
                                onClick={() => {
                                  this.handleAddAddOn(id);
                                }}
                              >
                                {!this.state.addOnSubTabVisible[id] ? <p style={{fontSize:'10px'}}>Add Substitute</p> : <p style={{fontSize:'12px',fontWeight:'bolder'}}>Done</p>}
                              </button>
                              <button
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  padding: '8px 16px 8px 12px',
                                  gap: '10px',
                                  color: '#FF754C',
                                  borderRadius: '12px',
                                  border: '1px solid #FF754C',
                                  background: 'white',
                                  width: '11rem',
                                  marginLeft:'1rem'
                                }}
                                onClick={() => {
                                  this.handleClearAddOn(id);
                                }}
                              >
                                {<p style={{ fontSize: '10px', color:'#FF754C' }}>Clear</p>}
                              </button>
                            </div>
                          </div>
                          {this.state.addOnSubTabVisible[id] &&
                            <div style={{
                              marginBottom:'12px'
                            }}>
                              
                              <p style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                fontWeight: 500,
                                fontSize: '10px',
                                lineHeight: '130%',
                                letterSpacing: '0.25px',
                                color: '#767787'
                              }}><p
                                style={{
                                    color: '#ff754c',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  bottom: '0'
                                  // Add some space between text and dot
                                }}
                              >
                                  Note : 
                                </p>&nbsp; Please try to search for prescribed medicine before searching substitute</p>
                              <AsyncCreatableSelect
                              styles={customStyles}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary50: '#d5d5d5',
                                  primary25: '#f5f5f5',
                                  primary: '#4F34D2',
                                },
                              })}
                              value={this.state.addOnMedicines[id]?.addOnMedicineSelectedOption}
                              onChange={(option) => this.setSelectedAddOnOption(id, option)}
                              loadOptions={(input, callback) => this.loadAddOnOptions(input, id, callback)}
                              placeholder="Search Substitute Medicine"
                              isClearable={true}
                              />
                              {/**
                               * DO NOT REMOVE 
                               * THIS SECTION IS COMMENTED ON SHAURYA'S INSTRUCTION TO AVOID DOCTOR TO ADD DIFFERENT DOSAGE
                              <InputContainer>
                                <StyledTappableCard
                                  onTap={() => this.addOnMedicineSelectMealPref(id, 'before')}
                                  isSelected={this.state.addOnMedicines[id]?.mealPref === 'before' ? true : false}
                                >
                                  <TappableText
                                    isSelected={this.state.addOnMedicines[id]?.mealPref === 'before' ? true : false}
                                  >
                                    Before food
                                  </TappableText>
                                </StyledTappableCard>
                                <StyledTappableCard
                                  onTap={() => this.addOnMedicineSelectMealPref(id, 'after')}
                                  isSelected={this.state.addOnMedicines[id]?.mealPref === 'after' ? true : false}
                                >
                                  <TappableText
                                    isSelected={this.state.addOnMedicines[id]?.mealPref === 'after' ? true : false}
                                  >
                                    After food
                                  </TappableText>
                                </StyledTappableCard>
                              </InputContainer>
                              <InputContainer>
                                <AgeContainer>
                                  <LabelText>Dosage</LabelText>
                                  <SelectElement
                                    options={this.dosageOptions}
                                    defaultValue={this.state.addOnMedicines[id]?.dosageOption}
                                    styles={customStyles}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary50: '#d5d5d5',
                                        primary25: '#f5f5f5',
                                        primary: '#4F34D2',
                                      },
                                    })}
                                    placeholder="Select Dosage"
                                    onChange={(option) => this.onSubstituteMedicineDosageSelect(id, option)}
                                  />
                                </AgeContainer>
                                <AgeContainer>
                                  <LabelText>Duration</LabelText>
                                  <DosageContainer>
                                    <input
                                      style={{
                                        borderBottom: '1px solid #979797 !important',
                                        padding: '0.33rem 0',
                                        margin: '0rem 0.625rem;',
                                        width: '8rem',
                                      }}
                                      type="text"
                                      className="visit-input"
                                      defaultValue={0}
                                      value={this.state.addOnMedicines[id]?.duration}
                                      onChange={(event) => this.setSubstituteMedicineDurationValue(id, event.target.value)}
                                      placeholder='0'
                                    >
                                    </input>
                                    <SelectElement
                                      options={this.durationOptions}
                                      styles={customStyles}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary50: '#d5d5d5',
                                          primary25: '#f5f5f5',
                                          primary: '#4F34D2',
                                        },
                                      })}
                                      defaultValue={this.state.addOnMedicines[id]?.durationOption}
                                      placeholder={this.state.addOnMedicines[id]?.durationOption}
                                      onChange={(option) => this.onSubstituteMedicineDurationSelect(id, option)}
                                    />
                                  </DosageContainer>
                                </AgeContainer>
                              </InputContainer>
                              <InputContainer>
                                {this.state.addOnMedicines[id]?.dosage === 'Other' ? (
                                  <React.Fragment>
                                    <AgeContainer>
                                      <input
                                        type="text"
                                        className="visit-input"
                                        value={this.state.addOnMedicines[id]?.otherDosage}
                                        onChange={(event) => this.setSubstituteMedicineOtherDosageValue(id, event.target.value)}
                                        placeholder="Dosage Description"
                                      />
                                    </AgeContainer>
                                    <AgeContainer>
                                      <input
                                        type="number"
                                        className="visit-input"
                                        value={this.state.addOnMedicines[id]?.prescribedQuantity}
                                        onChange={(event) => this.setSubstituteMedicinePrescribedQuantityValue(id, event.target.value)}
                                        placeholder="Prescribed Quantity"
                                      />
                                    </AgeContainer>
                                  </React.Fragment>
                                ) : (
                                  ''
                                )}
                              </InputContainer>
                              */
                              }
                            </div>
                          }
                        </div>
                      );
                    })
                    }
                    <ButtonComponent style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                      <Button
                        onTap={() => {
                          this.addItemToCart(this.state.selectedGroupOfMedicines, this.state.addOnMedicines);
                          this.clearFields();
                        }}
                        disabled={
                          /**
                          * DO NOT REMOVE 
                           * THIS SECTION IS COMMENTED ON SHAURYA'S INSTRUCTION TO AVOID DOCTOR TO ADD DIFFERENT DOSAGE
                           * 
                           ((this.state.dosage.length <= 0 || (this.state.durationValue <= 0 && !this.state.prescribedQuantity)) || 
                           (this.state.addOnMedicines && (Object.values(this.state.addOnMedicines).filter( substitueMed => {
                           return ((substitueMed.dimDrugId && substitueMed.dosage.length <= 0)  || ( substitueMed.dimDrugId && substitueMed.durationValue <= 0 && !substitueMed.prescribedQuantity))
                           })).length))
                          */
                          
                          ((this.state.isSubstitute && !this.state.prescribedDrugName?.length) || (this.state.dosage.length <= 0 || (this.state.durationValue <= 0 && !this.state.prescribedQuantity))) && (this.state.coveredUnderOpd || (this.state.isSubstitute && !this.state.prescribedDrugName?.length)) 
                        }
                        
                      >
                        <ButtonText>Add Medicine</ButtonText>
                      </Button>
                    </ButtonComponent>
                  </div>
                  }
                </div>
                <div style={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', borderLeft: '1px solid #E4E4E4', margin: '1rem' }}>
                  <LabelText style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', fontSize: '16px', letterSpacing: '0.25px', textTransform: 'capitalize', fontWeight: 'bolder', color: '#0F0B28', margin: '1rem' }}>
                    Your Cart
                  </LabelText>
                  <div style={{ padding: '0.5rem' }} className="text-body-s bg-divBg mb-10px rounded-xl">
                    <p style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                      <span style={{ color: '#FF754C', display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>Substitutes(<p
                        style={{
                          color: '#FF754C',
                          fontSize: '13px',
                          fontWeight: 900,
                          bottom: '0'
                          // Add some space between text and dot
                        }}
                      >
                        &bull;
                      </p>)
                        <p style={{
                          color: '#767787'
                        }}>Please thoroughly verify for substituted medicines</p>
                      </span>
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <MedicineNameBasedCartItemListModel
                      reloadCartItems={(result) => this.setState({
                        cartItems: result,
                      })}
                      result={this.state.cartItems?.length ? this.state.cartItems : []} />
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  prescriptions: state.info.prescriptions,
  digitisationDetails: state.digitisation,
});

export default connect(mapStateToProps)(AddMedicinesModal);
